.audio_card_container{
  .audio_card_body{
    text-align: center;
    justify-content: center;
    align-items: center;
    padding:1rem;
    @media screen and (max-width: 500px) {
      padding: 0.5rem; 
    }
  }
  audio{
    max-width: 100%;
    object-fit: contain;
  }
}