#layout_body{

  min-height: 100vh;
  display:flex;
  flex-direction:column;
  margin:0;
  #layout_header{
    user-select:none;
    position: fixed;
    display: flex;
    justify-content:center;
    align-items:center;
    height:3.5rem;
    background-color: #07205d;
    width: 100vw;
    z-index: 2;
    padding : 2rem;
    img{
      height:2.5rem;
      margin:0.5rem;
    }
  }

  #layout_main{
    margin-top: 3.5rem;
    min-height: calc(100vh - 3.5rem - 2rem);
    background-color: #e4e5e6;
  }

  #bottomBannerContainer{
    @media (min-width: 992px) { 
      display: none;
    }
  }

  #layout_footer{
    user-select:none;
    display: flex;
    height:2rem;
    background-color: #07205d;
    justify-content: center;
    align-items: center;
    p{
      display: flex;
      justify-content: center;
      align-items: center;
      color: #fff;
      font-size: x-small;
      text-align: center;
      margin: 0;
    }
  }
}