#bottom_banner_container{
    img{
        margin: auto;
        object-fit: contain;
        width: auto;
        height: auto;  
        display: block;
        max-width:100%;
        max-height:200px;
        margin-bottom: 3vh;
        border-radius: 0.5rem;
    }
}