@import url("https://fonts.googleapis.com/css?family=Montserrat:400,300,700");

@import url('https://fonts.googleapis.com/css?family=Open+Sans');


body {
  font-family: "Montserrat", "Open Sans", sans-serif !important;
}

$theme-colors: (
  "primary": #07205d,
);



@import "~bootstrap/scss/bootstrap";