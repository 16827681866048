.video_card_container{
  video{
    width: 100%;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
      @media screen and (max-width: 500px) {
        margin-top: 0.25rem;
        margin-bottom: 0.25rem;
    }
  }
}