.PDF_card_container{
  .file_card_body{
    margin-top: 1rem;
    margin-bottom: 1rem;
    .img_container{
      position: relative;
      overflow: hidden;
      max-height: 15rem;
    }
    .img_icon_container{
      position: absolute;
      bottom: 1.5rem;
      right: 1.5rem;
      z-index: 1;
      width: 2.6rem;
      height: 2.6rem;
      background-color: rgba($color: #FFFF, $alpha: 0.6);
      padding: 0.5rem;
      border-radius: 100%;
    }
    img{
      max-width: 100%;
    }
  }
}