#projections-menu-container{
  margin-top: 3vh!important;
  margin-bottom: 3vh!important;
  @media screen and (min-width: 1200px) {
    margin-bottom: 3vh;
  }
  @media screen and (max-width: 500px) {
    #projections_col_container{
      padding-right: 0;
      padding-left: 0;
    }
  }
  .title{
    h2{
      color: #07205d;
      font-weight: bolder;
    }
  }
  .report_text{
    color: #07205d;
  }
  a{
    color: #384c7c;
  }
  a:hover{
    color: #384c7c;
  }

  #projections_card{
    .card-body{
      padding-left: 0;
      padding-right: 0;
    }
  }


  #AnualProjectionsTable{
    thead{
      tr{
        th{
          background-color: #07205d;
          color: white;
          font-weight: bolder;
        }
      }
    }
    tbody{
      .table_subtitle{
        background-color: #0ca6ff;
        color: white;
        font-weight: bolder;
        .openChartsIcon{
          cursor: pointer;
          &:hover{
            color: #07205d;
          }
        }
      }
    }
  }
}