.medium-loader{
  width: fit-content;
  padding: 1rem;
  border-radius: 0.5rem;
  margin: auto;
  margin-top: 5vh;
  margin-bottom: 5vh;
  img{
    height: 15vh;
  }
}