.expirations-menu-container{
  margin-top: 3vh!important;
  margin-bottom: 3vh!important;
  @media screen and (max-width: 500px) {
    #expirations_col_container{
      padding-right: 0!important;
      padding-left: 0!important;
    }
  }
  #sideBanner{
    @media screen and (max-width: 769px) {
      display: none;
    }
  }
  #bottomBanner{
    @media screen and (min-width: 769px) {
      display: none;
      margin-top: 2rem;
    }
  }
  .title{
    h2{
      color: #07205d;
      font-weight: bolder;
    }
  }
  .report_text{
    color: #07205d;
  }
  a{
    color: #384c7c;
  }
  a:hover{
    color: #384c7c;
  }
}

