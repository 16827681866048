
.loader-container{
  margin-top: 15vh;
  margin-bottom: 5vh;
}

.error-container{
  margin-top: 15vh;
  margin-bottom: 5vh;
 button{
  background-color: #07205d;
  border-color: #07205d;
  margin: 0;
  :hover{
    background-color: #07205d;
  }
 }
}

.report-container{
  margin-top: 3vh !important;
  margin-bottom: 3vh!important;
  @media screen and (min-width: 1200px) {
    margin-bottom: 3vh;
  }
  @media screen and (max-width: 500px) {
    #report_col_container{
      padding-right: 0;
      padding-left: 0;
    }
  }
  .title{
    h3{
      color: #07205d;
      font-weight: bolder;
    }
  }
  .type{
    h5{
      font-weight: bolder;
      color: #0ca6ff;
    }
  }
  .date{
    color: #07205d;
    font-weight: bolder;
  }
  .report_text{
    color: #07205d;
  }
}

.file-container{
  margin-top: 2vh;
  margin-bottom: 4vh;
  .file_card{
    .card-title{
      text-align: left;
    }
  }
}