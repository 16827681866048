#RightBanner{
    margin-top: 3vh;
    display: block;
    max-width:100%;
    max-height:1000px;
    object-fit: contain;
    width: auto;
    height: auto;
    margin-bottom: 3vh;
    object-position: top;
    border-radius: 0.5rem;
    @media (max-width: 991px) { 
        display: none;
    }
}