.image_card_container{
  .img_card{
    position: relative;
    .report_img{
      max-width: 100%;
      height: auto;
      margin-top: 0.5rem;
      margin-bottom: 0.5rem;
      @media screen and (max-width: 500px) {
        margin-top: 0.25rem;
        margin-bottom: 0.25rem; 
      }
    }
  }
  .img_icon_container{
    position: absolute;
    bottom: 4.5rem;
    right: 1.5rem;
    z-index: 1;
    width: 2.6rem;
    height: 2.6rem;
    background-color: rgba($color: #FFFF, $alpha: 0.6);
    padding: 0.5rem;
    border-radius: 100%;
    img{
      max-width: 100%;
    }
  }
}