#InviteScreenContainer{
    margin-top: 3vh!important;
    margin-bottom: 3vh!important;
    .title{
        h3{
            color: #07205d;
            font-weight: bolder;
          }
          h5{
            color: #07205d;
          }
    }
    #qr_container{
        svg{
            margin-top: 3vh;
            margin-bottom: 3vh; 
            object-fit: contain;
            object-position: center center;
        }
    }
}